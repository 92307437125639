<template>
  <div>
    <div class="card card-custom card-shadowless d-flex align-items-center">
      <div class="card-body p-0 card d-flex w-xs-100 w-sm-75 w-md-50">
        <!--begin: Wizard-->
        <div
          class="wizard wizard-1"
          id="kt_wizard_v1"
          data-wizard-state="step-first"
          data-wizard-clickable="true"
        >
          <!--begin: Wizard Nav-->
          <div class="wizard-nav border-bottom">
            <div class="wizard-steps p-8">
              <div
                class="wizard-step"
                data-wizard-type="step"
                data-wizard-state="current"
              >
                <div class="wizard-label">
                  <i class="wizard-icon flaticon-security"></i>
                  <h3 class="wizard-title">1. Retrieve Key</h3>
                </div>
                <i class="wizard-arrow flaticon2-next"></i>
              </div>
              <div class="wizard-step" data-wizard-type="step">
                <div class="wizard-label">
                  <i class="wizard-icon flaticon2-safe"></i>
                  <h3 class="wizard-title">2. Enter Key</h3>
                </div>
                <i class="wizard-arrow flaticon2-next"></i>
              </div>
              <div class="wizard-step" data-wizard-type="step">
                <div class="wizard-label">
                  <i class="wizard-icon flaticon-safe-shield-protection"></i>
                  <h3 class="wizard-title">3. Confirmation</h3>
                </div>
                <i class="wizard-arrow flaticon2-next"></i>
              </div>
            </div>
          </div>
          <!--end: Wizard Nav-->

          <!--begin: Wizard Body-->
          <div class="row justify-content-center my-10 px-8">
            <div class="col-xl-12">
              <!--begin: Wizard Form-->

              <!-- begin: Wizard Step 1 -->
              <div
                class="pb-5"
                data-wizard-type="step-content"
                data-wizard-state="current"
              >
                <h5 class="d-flex pb-5 subtitle">
                  If you are a trustee, you can use this page to confirm the
                  death of a {{ appName }} user. This will dispatch messages
                  they have prepared for friends and loved ones.<br /><br />
                  To proceed, please have your key ready and click on the next
                  step button to get started.<br /><br />
                  If you do not know the unlock key, please enter your email
                  address below, and it will be sent to you if you are a valid
                  and active trustee.
                </h5>
                <!-- begin:: intro form -->
                <div class="form-group">
                  <form id="frmIntro" @submit.prevent>
                    <div class="form-group row">
                      <div class="col-md-7 col-xs-12">
                        <input
                          id="txt_search_email"
                          type="text"
                          class="form-control"
                          name="email"
                          placeholder="Email Address"
                          v-model="trusteeEmail"
                        />
                      </div>
                      <div class="col-md-5 col-xs-12">
                        <button
                          ref="btn_search_account"
                          @click="searchAccount()"
                          class="btn btn-info form-control"
                          :class="searchClass"
                        >
                          {{ apiBusy ? "loading" : "Retrieve" }}
                          <i v-show="!apiBusy" class="ml-5 fas fa-key"></i>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>

                <!-- end:  intro form  -->
              </div>
              <!-- Begin: Wizard Step 2 -->
              <!--begin: Wizard Step 2-->
              <div class="pb-5" data-wizard-type="step-content">
                <h4 class="mb-10 font-weight-bold text-dark">
                  Please enter your secret key to verify your trusteeship and
                  begin unlocking an account.
                </h4>

                <div class="form-group">
                  <form class="form" id="frmVerifySecretKey">
                    <label>Secret Key</label>
                    <div :class="verifyKeyClass">
                      <input
                        v-model="trusteeKey"
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        name="secretKey"
                        value=""
                      />
                    </div>
                  </form>
                </div>
              </div>
              <!--end: Wizard Step 2-->

              <!--begin: Wizard Step 3-->
              <div class="pb-5" data-wizard-type="step-content">
                <h4 class="mb-10 font-weight-bold text-dark">
                  Hello {{ lockData.name }}.
                </h4>
                <h4>
                  Do you confirm that
                  <span class="highlight">{{ userDisplayName }}</span> has
                  passed on, and that you want to unlock their messages to loved
                  ones?
                </h4>
                <br />
                <p>
                  Please note that this is an irreversible process. Once you
                  click confirm, all {{ userDisplayName }}'s messages stored on
                  {{ appName }} will be queued for dispatched.<br />
                  <br />
                  Also bear in mind that this account is guarded by two other
                  people. The messages will only be unlocked and dispatched once
                  all of them have entered their keys. After confirming, they
                  will receive a notification to unlock this account as well.
                </p>
              </div>
              <!--end: Wizard Step -->

              <!--begin: Wizard Actions -->
              <div
                :class="showWizardNav ? 'd-flex' : 'd-none'"
                class="wizard-navigation justify-content-between border-top pt-10"
              >
                <div class="mr-2">
                  <button
                    class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-prev"
                  >
                    Back
                  </button>
                </div>
                <div>
                  <button
                    v-on:click="submit"
                    :class="apiBusy ? buttonSpinner : ''"
                    class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-submit"
                  >
                    Confirm
                  </button>
                  <button
                    class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-next"
                  >
                    Next Step
                  </button>
                </div>
              </div>
              <!--end: Wizard Actions -->

              <!--end: Wizard Form-->
            </div>
          </div>
          <!--end: Wizard Body-->
        </div>
      </div>
      <!--end: Wizard-->
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-1.scss";
.box {
  border: 1px solid #eee;
  padding: 10px;
}

.btn {
  font-weight: bold;
}

h4 {
  line-height: 1.5em;
}

.title {
  font-size: 2em;
}
.subtitle {
  line-height: 1.8em;
}
.highlight {
  border-bottom: 2px dotted #aaa;
}
</style>

<script>
import {
  SEARCH_TRUSTEE_ACCOUNT,
  VERIFY_TRUSTEE_KEY,
  UNLOCK_TRUSTEE
} from "@/core/services/store/lock.module.js";
import { mapGetters } from "vuex";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";

export default {
  name: "Unlock",
  data: function() {
    return {
      showWizardNav: true,
      trusteeEmail: "",
      trusteeKey: ""
    };
  },

  computed: {
    ...mapGetters([
      "appName",
      "inputSpinner",
      "buttonSpinner",
      "apiBusy",
      "lockData"
    ]),
    searchClass() {
      if (this.apiBusy) {
        return this.buttonSpinner;
      } else {
        return "";
      }
    },
    verifyKeyClass() {
      if (this.apiBusy) {
        return this.inputSpinner;
      } else {
        return "";
      }
    },
    userDisplayName() {
      return this.lockData.user ? this.lockData.user.display_name : "";
    },
    trusteeName() {
      return this.lockData ? this.lockData.name : "";
    }
  },

  mounted() {
    // create form validators
    const introForm = KTUtil.getById("frmIntro");
    const verifySecretKey = KTUtil.getById("frmVerifySecretKey");

    // initialize the wizard
    this.wizard = new KTWizard("kt_wizard_v1", {
      startStep: this.STEP_SEARCH_TRUSTEE,
      clickableSteps: true
    });

    // listen to wizard step changes
    this.wizard.on("change", wizardObject => {
      this.changeWizardStep(wizardObject);
    });

    // initialize validation for into page
    this.fvIntro = formValidation(introForm, {
      fields: {
        email: {
          validators: {
            emailAddress: {
              message: "Please enter a valid email address"
            },
            notEmpty: {
              message: "Please enter a valid email address"
            }
          }
        }
      },

      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    this.fvVerifySecretKey = formValidation(verifySecretKey, {
      fields: {
        secretKey: {
          validators: {
            notEmpty: {
              message: "Please enter the secret key"
            }
          }
        }
      },

      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap({})
      }
    });
  },
  methods: {
    show(section) {
      if (section == "introduction") {
        this.showIntroduction = true;
        this.showWizard = false;
      } else {
        this.showWizard = true;
        this.showIntroduction = false;
      }
    },

    changeWizardStep(wizardObj) {
      const currentStep = wizardObj.getStep();
      switch (currentStep) {
        case 1:
          break;
        case 2:
          // if next step is the next slide (i.e. user is not going backwards, then stop and do validation of the key)
          if (wizardObj.getNewStep() > 2) {
            // stop the change
            wizardObj.stop();

            // verify the key before going to confirm
            this.verifySecretKey();
          }

          break;
        case 3:
          break;

        default:
          break;
      }
    },
    searchAccount() {
      // convert button into loading

      // do validation
      this.fvIntro.validate().then(status => {
        if (status == "Valid") {
          this.$store

            // dispatch action to search for accounts using email address
            .dispatch(SEARCH_TRUSTEE_ACCOUNT, this.trusteeEmail)

            // show message to user, and prompting them to check email address
            .then(() => {
              const wizard = this.wizard;
              Swal.fire({
                text:
                  "Please check your email inbox.  If you have not received the key, please check junk mail",
                icon: "success",
                buttonsStyling: false,
                heightAuto: false,
                showCancelButton: true,
                confirmButtonText: "Got it, proceed to next step.",
                customClass: {
                  confirmButton: "btn font-weight-bold btn-light-primary",
                  cancelButton: "btn font-weight-bold btn-default"
                }
              }).then(result => {
                if (result.isConfirmed) {
                  // go to the next step
                  wizard.goNext();
                }
              });
            })

            .finally(() => {
              // @todo:  unset button from spinner mode
            });
        } else {
          console.log("validation failed: " + status);
        }
      });
    },

    verifySecretKey() {
      // set the text to loading...

      // 1. Do Validation
      this.fvVerifySecretKey.validate().then(status => {
        if (status == "Valid") {
          console.log(this.trusteeKey);

          this.$store
            .dispatch(VERIFY_TRUSTEE_KEY, this.trusteeKey)

            .then(() => {
              this.wizard.goNext();
            })

            .catch(error => {
              Swal.fire({
                text: error.message,
                icon: "error",
                buttonsStyling: false,
                heightAuto: false,
                showCancelButton: true,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn font-weight-bold btn-light-primary",
                  cancelButton: "btn font-weight-bold btn-default"
                }
              });
            })

            .finally(() => {
              console.log("done");
            });
        }
      });

      // 2.  Check if the key is correct

      // 3.  Switch to the next step
    },

    submit() {
      // Right, let's do the things
      this.$store
        .dispatch(UNLOCK_TRUSTEE, this.lockData.id)

        .then(() => {
          Swal.fire({
            text:
              "Key was successfully used. Thank you for being a loyal trustee.",
            icon: "success",
            buttonsStyling: false,
            heightAuto: false,
            showCancelButton: false,
            confirmButtonText: "Go To Home Page",
            customClass: {
              confirmButton: "btn font-weight-bold btn-light-primary",
              cancelButton: "btn font-weight-bold btn-default"
            }
          }).then(() => {
            this.$router.push({ name: "dashboard" });
          });
        })

        .catch(error => {
          Swal.fire({
            text: error.message,
            icon: "error",
            buttonsStyling: false,
            heightAuto: false,
            showCancelButton: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn font-weight-bold btn-light-primary",
              cancelButton: "btn font-weight-bold btn-default"
            }
          });
        });
    }
  }
};
</script>
